import React from 'react'

import { Pages } from '../../../enums'

import Container from '../../Container'

type NavigationProps = {
  activePage: Pages
}

const Navigation = ({ activePage }: NavigationProps) => {
  return (
    <Container>
      <ul className="main-nav__list">
        <li>
          <a
            href="/mieze-nova"
            className={activePage === Pages.MIEZE_NOVA ? 'active' : null}
          >
            <span className="category">Erdbeere</span>
            <span className="fruit-name">{Pages.MIEZE_NOVA}</span>
          </a>
        </li>
        <li>
          <a
            href="/aroma-queen"
            className={activePage === Pages.AROMA_QUEEN ? 'active' : null}
          >
            <span className="category">Himbeere</span>
            <span className="fruit-name">{Pages.AROMA_QUEEN}</span>
          </a>
        </li>
        <li>
          <a
            href="/bella-aromatica"
            className={activePage === Pages.BELLA_AROMATICA ? 'active' : null}
          >
            <span className="category">Himbeere</span>
            <span className="fruit-name">{Pages.BELLA_AROMATICA}</span>
          </a>
        </li>
        <li>
          <a
            href="/mini-cox"
            className={activePage === Pages.MINI_COX ? 'active' : null}
          >
            <span className="category">Zwergapfel</span>
            <span className="fruit-name">{Pages.MINI_COX}</span>
          </a>
        </li>
        <li>
          <a
            href="/coxcolumnar"
            className={activePage === Pages.COXCOLUMNAR ? 'active' : null}
          >
            <span className="category">Säulenapfel</span>
            <span className="fruit-name">{Pages.COXCOLUMNAR}</span>
          </a>
        </li>
      </ul>
    </Container>
  )
}

export default React.memo(Navigation)
