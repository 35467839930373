import React, { useState } from 'react'

import { Pages } from '../../enums'

import Container from '../Container'

import Navigation from './Navigation'

type MainNavigationProps = {
  activePage: Pages
}

const MainNavigation = ({ activePage }: MainNavigationProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleMenuClick = () => {
    setMenuIsOpen(!menuIsOpen)
  }
  return (
    <>
      <nav className="main-nav desktop">
        <Navigation activePage={activePage} />
      </nav>
      <nav className="main-nav mobile">
        <Container>
          <a id="menu" className="menu" onClick={handleMenuClick}>
            <span className="fa fa-navicon"></span>
          </a>
          <div id="menuDropDown" className={menuIsOpen ? 'open' : null}>
            <Navigation activePage={activePage} />
          </div>
        </Container>
      </nav>
    </>
  )
}

export default React.memo(MainNavigation)
