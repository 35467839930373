import React from 'react'

const Copyright = () => {
  const currentYear = new Date().getFullYear()

  return (
    <p className="copyright">
      &copy; {currentYear} Neue Obstsorten. Dr. Barbara Dathe.
    </p>
  )
}

export default React.memo(Copyright)
