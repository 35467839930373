import React from 'react'

const ToTopButton = () => {
  return (
    <a href="#header" className="to-top">
      <span className="fa fa-arrow-up"></span>
    </a>
  )
}

export default React.memo(ToTopButton)
