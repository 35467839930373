import React from 'react'
import Sticky from 'react-sticky-el'

import { Pages } from '../../enums'

import Seo from '../../components/Seo'
import Header from '../../components/Header'
import MainNavigation from '../../components/MainNavigation'
import Main from '../../components/Main'
import Footer from '../../components/Footer'
import ToTopButton from '../../components/ToTopButton'

import '../../scss/styles.scss'

type DefaultLayoutProps = {
  title: string
  description?: string
  activePage: Pages
  children: React.ReactChild | React.ReactChild[]
}

const DefaultLayout = ({
  title,
  description,
  activePage,
  children,
}: DefaultLayoutProps) => {
  return (
    <>
      <Seo title={title} description={description} />

      <Header activePage={activePage} />

      <Sticky>
        <MainNavigation activePage={activePage} />
      </Sticky>

      <Main>{children}</Main>

      <ToTopButton />

      <Footer activePage={activePage} />

      <div id="lightbox-root"></div>
    </>
  )
}

export default React.memo(DefaultLayout)
