import React from 'react'

import { Pages } from '../../enums'

type MetaNavigationProps = {
  activePage: Pages
}

const MetaNavigation = ({ activePage }: MetaNavigationProps) => {
  return (
    <nav className="meta-nav">
      <ul>
        <li>
          <a href="/" className={activePage === Pages.HOME ? 'active' : null}>
            {Pages.HOME}
          </a>
        </li>
        <li>
          <a
            href="/contact"
            className={activePage === Pages.CONTACT ? 'active' : null}
          >
            {Pages.CONTACT}
          </a>
        </li>
        <li>
          <a
            href="/impressum"
            className={activePage === Pages.IMPRINT ? 'active' : null}
          >
            {Pages.IMPRINT}
          </a>
        </li>
        <li>
          <a
            href="/data-protection"
            className={activePage === Pages.DATA_PROTECTION ? 'active' : null}
          >
            {Pages.DATA_PROTECTION}
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default React.memo(MetaNavigation)
