export enum Pages {
  HOME = 'Startseite',
  CONTACT = 'Kontakt',
  IMPRINT = 'Impressum',
  DATA_PROTECTION = 'Datenschutz',
  MIEZE_NOVA = 'Mieze Nova (s)',
  AROMA_QUEEN = 'Aroma Queen ®',
  BELLA_AROMATICA = 'Bella Aromatica ®',
  MINI_COX = 'Mini-Cox ®',
  COXCOLUMNAR = 'Coxcolumnar ®',
  PAGE_404 = 'Seite nicht gefunden',
}
