import React from 'react'

type ContainerProps = {
  children: React.ReactChild | React.ReactChild[] | any
}

const Container = ({ children }: ContainerProps) => {
  return <div className="container">{children}</div>
}

export default React.memo(Container)
