import React from 'react'

import { Pages } from '../../enums'

import Container from '../Container'
import MetaNavigation from '../MetaNavigation'

import Logo from './Logo'

type HeaderProps = {
  activePage: Pages
}

const Header = ({ activePage }: HeaderProps) => {
  return (
    <header id="header" className="header">
      <Container>
        <MetaNavigation activePage={activePage} />
        <Logo />
      </Container>
    </header>
  )
}

export default React.memo(Header)
