import React from 'react'

const Logo = () => {
  return (
    <div className="logo">
      <a href="/">
        <p className="logo-title">Neue Obstsorten</p>
        <p className="logo-subtitle">
          <span>
            Dr. Barbara Dathe - Züchtung neuer Obstsorten für den Kleingarten.
          </span>
        </p>
      </a>
    </div>
  )
}

export default React.memo(Logo)
