import React from 'react'

type MainProps = {
  children: React.ReactChild | React.ReactChild[]
}

const Main = ({ children }: MainProps) => {
  return <main className="main">{children}</main>
}

export default React.memo(Main)
