import React from 'react'

type SectionProps = {
  children: React.ReactChild | React.ReactChild[]
  className?: string
}

const Section = ({ children, className }: SectionProps) => {
  return <section className={className}>{children}</section>
}

export default React.memo(Section)
