import React from 'react'
import Helmet from 'react-helmet'

type MetaProps = {
  description?: string
  title: string
}

const Seo = ({ description, title }: MetaProps) => {
  return (
    <Helmet>
      <meta
        name="description"
        content={
          description
            ? description
            : 'Erdbeere Mieze Nova, Himbeere Aroma Queen, Zwergapfel Mini-Cox, Säulenapfel Coxcolumnar als Züchtung von Obstzüchterin Dr. Barbara Dathe aus Pirna.'
        }
      />
      <title>{`Neue Obstsorten | ${title}`}</title>
    </Helmet>
  )
}

export default React.memo(Seo)
