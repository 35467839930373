import React from 'react'

import { Pages } from '../../enums'

import Container from '../Container'
import MetaNavigation from '../MetaNavigation'

import Copyright from './Copyright'

type FooterProps = {
  activePage: Pages
}

const Footer = ({ activePage }: FooterProps) => {
  return (
    <footer className="footer">
      <Container>
        <MetaNavigation activePage={activePage} />
        <Copyright />
      </Container>
    </footer>
  )
}

export default React.memo(Footer)
